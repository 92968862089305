import { useApiTypes } from '@/lib/store';
import { Box, Button, Fieldset, Grid, Group, NumberInput, Select, Switch, TextInput, Textarea } from '@mantine/core';
import {
    IconClock,
    IconDeviceFloppy,
    IconId,
    IconLink,
    IconScaleOutline,
    IconScaleOutlineOff,
    IconSolarElectricity,
    IconSolarPanel2,
    IconWorldWww,
} from '@tabler/icons-react';

const PlantDetailsForm = ({
    onSubmit,
    form,
    activeChecked,
    maintenanceChecked,
    repairingChecked,
    sendAlertNotificationChecked,
    setActiveChecked,
    setMaintenanceChecked,
    setRepairingChecked,
    setSendAlertNotificationChecked,
}) => {
    const { apiTypes } = useApiTypes();

    return (
        <Box p="md" component="form" onSubmit={onSubmit}>
            <Fieldset legend="Stato impianto">
                <Grid>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        <TextInput
                            leftSection={<IconSolarPanel2 size="1.2rem" />}
                            label="Nome Impianto"
                            withAsterisk
                            placeholder="Inserisci il nome dell'impianto"
                            {...form.getInputProps('name')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        <Select
                            label="Tipologia"
                            leftSection={<IconLink size="1.2rem" />}
                            data={apiTypes.map((apiType) => {
                                return { value: apiType.value.toString(), label: apiType.label };
                            })}
                            allowDeselect={false}
                            {...form.getInputProps('apiType')}
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Group>
                            <Switch
                                checked={maintenanceChecked}
                                color="yellow"
                                onChange={(e) => setMaintenanceChecked(e.target.checked)}
                                size="sm"
                                label="In Manutenzione"
                            />
                            <Switch
                                checked={repairingChecked}
                                color="orange"
                                onChange={(e) => setRepairingChecked(e.target.checked)}
                                size="sm"
                                label="In Riparazione"
                            />
                            <Switch
                                checked={activeChecked}
                                color="teal"
                                onChange={(e) => setActiveChecked(e.target.checked)}
                                size="sm"
                                label="Impianto Attivo"
                            />
                            <Switch
                                checked={sendAlertNotificationChecked}
                                onChange={(e) => setSendAlertNotificationChecked(e.target.checked)}
                                size="sm"
                                label="Invia Avvisi"
                            />
                        </Group>
                    </Grid.Col>
                </Grid>
            </Fieldset>
            <Fieldset mt="md" legend="Dettagli Tecnici">
                <Grid>
                    <Grid.Col span={6}>
                        <TextInput
                            leftSection={
                                form.values.apiType === '1' ? <IconWorldWww size="1.2rem" /> : <IconId size="1.2rem" />
                            }
                            label={form.values.apiType === '1' ? 'URL' : 'ID Impianto'}
                            description={
                                form.values.apiType === '1'
                                    ? "URL di connessione all'impianto fotovoltaico"
                                    : form.values.apiType === '2'
                                    ? "Codice numerico identificativo dell'impianto SolarEdge"
                                    : "Codice numerico identificativo dell'impianto Zucchetti"
                            }
                            withAsterisk
                            placeholder={
                                form.values.apiType === '1'
                                    ? "Inserisci l'URL dell'impianto"
                                    : "Inserisci il numero identificativo dell'impianto"
                            }
                            {...form.getInputProps('url')}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <NumberInput
                            placeholder="Inserisci Potenza impianto"
                            clampBehavior="strict"
                            min={0.01}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            label="Potenza impianto"
                            description="Potenza nominale dell'impianto fotovoltaico"
                            stepHoldDelay={500}
                            withAsterisk
                            stepHoldInterval={100}
                            leftSection={<IconSolarElectricity size="1.2rem" />}
                            {...form.getInputProps('plantPower')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4 }}>
                        <NumberInput
                            placeholder="Inserisci P/R"
                            clampBehavior="strict"
                            min={0}
                            max={100}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            label="P/R [%]"
                            stepHoldDelay={500}
                            stepHoldInterval={100}
                            {...form.getInputProps('targetPerformanceRatio')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4 }}>
                        <NumberInput
                            placeholder="Inserisci CdT"
                            clampBehavior="strict"
                            min={0}
                            decimalScale={3}
                            decimalSeparator=","
                            thousandSeparator="."
                            label="CdT"
                            stepHoldDelay={500}
                            stepHoldInterval={100}
                            {...form.getInputProps('temperatureCoefficient')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4 }}>
                        <NumberInput
                            placeholder="Inserisci tara"
                            clampBehavior="strict"
                            min={0}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            label="Tara [KW]"
                            stepHoldDelay={500}
                            stepHoldInterval={100}
                            {...form.getInputProps('tare')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        <Select
                            label="Solarimetro"
                            leftSection={
                                Boolean(parseInt(form.values.solarimeter)) ? (
                                    <IconScaleOutline size="1.2rem" />
                                ) : (
                                    <IconScaleOutlineOff size="1.2rem" />
                                )
                            }
                            data={[
                                { label: 'Presente', value: '1' },
                                { label: 'Non Presente', value: '0' },
                            ]}
                            allowDeselect={false}
                            {...form.getInputProps('solarimeter')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        <NumberInput
                            leftSection={<IconClock size="1.2rem" />}
                            placeholder="Inserisci frequenza di aggiornamento"
                            clampBehavior="strict"
                            min={5}
                            decimalScale={0}
                            decimalSeparator=","
                            thousandSeparator="."
                            label="Frequenza di Aggiornamento [min]"
                            stepHoldDelay={500}
                            stepHoldInterval={100}
                            {...form.getInputProps('refreshInterval')}
                        />
                    </Grid.Col>
                </Grid>
            </Fieldset>
            <Textarea
                mt="md"
                label="Dettagli"
                placeholder="Inserisci i dettagli"
                autosize
                minRows={2}
                maxRows={4}
                {...form.getInputProps('details')}
            />
            <Button type="submit" size="compact-md" mt="md" leftSection={<IconDeviceFloppy />}>
                Salva
            </Button>
        </Box>
    );
};

export default PlantDetailsForm;
